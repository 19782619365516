<template>
  <div class="login">
    <div class="logo">
      <img src="@/assets/image/logo2.png" alt="" />
      <p><span>数码科技</span><span>服务家居</span></p>
    </div>
    <div class="btnWrapper">
      <van-button type="info" block>员工入口</van-button>
      <van-button type="info" block @click="$router.push({name:'order'})">安装师傅入口</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang='less' scoped>
.login {
  height: 100%;
  background-color: #231f1e;
  box-sizing: border-box;
  .logo {
    padding: 15% 20% 8%;
    // background-color: #5d9bfe;
    border-bottom: 1px solid #475058;
    img {
      width: 100%;
    }
    p {
      font-size: 32px;
      color: #ffffff;
      text-align: center;
      margin-top: 20px;
      letter-spacing: 4px;
      span:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
  .btnWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    padding: 0 10%;
    button:last-child {
      margin-top: 40px;
      border-radius: 10px;
    }
  }
}
</style>
